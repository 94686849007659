<!--  -->
<template>
  <div class="approval-li">
    <div class="row1">
      <div class="title">{{ info.platingNumber }}</div>
    </div>
    <div class="msg">
      <span>关联用车申请:{{ info.applyCode }}</span>
    </div>
    <div class="msg">
      <span>申请人:{{ info.staffName }}</span>
    </div>
    <div class="msg">
      <span>出车时间:{{ info.useBeginTime }}</span>
    </div>
    <div class="msg">
      <span>回车时间:{{ info.useEndTime }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  name: "approvalList",
  components: {},
  props: {
    info: Object,
  },
  computed: {
    designValue() {},
  },
  filters: {
    formatApproveStatus(val) {
      const rules = {
        0: "审批中",
        1: "审批通过",
        2: "审批驳回",
        3: "已撤销",
      };
      return rules[val] || val;
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.approval-li {
  width: 7.1rem;
  background: #ffffff;
  border-radius: 0.2rem;
  margin-bottom: 0.2rem;
  margin-left: 0.2rem;
  padding: 0.2rem;
  .row1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.1rem;
    .title {
      font-size: 0.3rem;
      font-weight: 600;
      color: #333333;
      line-height: 0.42rem;
    }

    .doing {
      border-radius: 0.08rem;
      font-size: 0.26rem;
      color: #fff;
      line-height: 0.37rem;
      padding: 0.06rem 0.12rem;
      margin-left: 0.2rem;
    }

    .approving {
      background: #1989fa;
    }
    .reject {
      background: #ee0a24;
    }
    .accept {
      background: #07c160;
    }

    .cancel {
      background: #999;
    }
  }
  .msg {
    word-break: break-all;
    font-size: 0.26rem;
    color: #999999;
    line-height: 0.37rem;
    margin-top: 0.04rem;
  }
  .row5 {
    display: flex;
    align-items: center;
    .submit-from {
      height: 0.37rem;
      font-size: 0.26rem;
      color: #333333;
      line-height: 0.37rem;
      flex: 1;
      white-space: nowrap;
      padding: 0.06rem 0;
    }

    .time {
      height: 0.3rem;
      font-size: 0.22rem;
      color: #999999;
      line-height: 0.3rem;
      white-space: nowrap;
    }
  }
}
</style>
