import request from "@/utils/request";

//车辆详情信息
export function cartInfo(params) {
  return request({
    url: `/cart/manage/cartInfo/${params.code}`,
    method: "get",
    params
  });
}


// 车辆列表
export function cartList(params) {
  return request({
    url: "cart/manage/cartList",
    method: "get",
    params: params,
  });
}

//可预约车辆列表
export function getAvailableList(params) {
  return request({
    url: "/cart/manage/getAvailableList",
    method: "get",
    params: params,
  });
}

//用车申请
export function useApply(data) {
  return request({
    url: "/cart/manage/useApply",
    method: "post",
    data: data,
  });
}


//用车审批
export function useApplyApprove(params) {
  return request({
    url: "/cart/manage/useApplyApprove",
    method: "get",
    params: params,
  });
}


//用车申请详情
export function useApplyInfo(params) {
  return request({
    url:  `/cart/manage/useApplyInfo/${params.applyCode}`,
    method: "get",
  });
}

// 用车记录
export function useApplyList(params) {
  return request({
    url:  `/cart/manage/useApplyList`,
    method: "get",
    params
  });
}



// 还车
export function returnCar(data) {
  return request({
    url:  `/cart/manage/returnCar`,
    method: "post",
    data
  });
}



// 查询个人名下未还车辆列表
export function getMyUnReturnList(params) {
  return request({
    url:  `/cart/manage/getMyUnReturnList`,
    method: "get",
    params
  });
}


// 我的审批记录
export function getMyApproveHistory(params) {
  return request({
    url:  `/cart/manage/getMyApproveHistory`,
    method: "get",
    params
  });
}

// 取消用车
export function cancleApply(params) {
  return request({
    url:  `/cart/manage/cancleApply/${params.applyCode}`,
    method: "get",
  });
}



// 编辑用车申请
export function editUseApply(data) {
  return request({
    url:  `/cart/manage/editUseApply`,
    method: "post",
    data
  });
}

// 还车详情
export function returnCarInfo(params) {
  return request({
    url:  `/cart/manage/returnCarInfo`,
    method: "get",
    params
  });
}

// 获取还车列表
export function retunCarList(params) {
  return request({
    url:  `/cart/manage/retunCarList`,
    method: "get",
    params
  });
}
